<ng-container>

    <table class="table table-striped table-bordered" id="dataTable">
        <thead>
            <tr class="cursor-pointer">
                <ng-container *ngFor="let column of gridHeaderMap">
                    <th scope="col" *ngIf="column.key !== 'id'">
                        {{ column.name }}
                    </th>
                </ng-container>
                <th scope="col" *ngIf="showAddColumn || showEditColumn || showDeleteColumn"></th>
                <!-- <th scope="col" *ngIf="showDeleteColumn"></th> -->
                <th scope="col" *ngIf="showActionsColumn">Actions</th>
            </tr>
        </thead>
        <tbody *ngIf="gridData.length > 0">
            <ng-container *ngFor="let item of gridData">
                <tr class="pointer">
                    <!-- <td>{{user.email}}</td>
                    <td>{{roleDisplayNameMap[user.role] || 'Invalid Role'}}</td>
                    <td>{{user.is_active ? 'Active' : 'Inactive'}}</td>
                    <td>{{user.created_on | date: 'MM-dd-yyyy'}}</td> -->
                    <ng-container *ngFor="let field of gridColumnList">
                        <td *ngIf="!showFields(field)"
                            [ngStyle]="{'text-align': isNumber(item[field]) ? 'right': 'left'}">
                            <ng-container *ngIf="isDate(item[field]); else currencyFormat">
                                {{ item[field] | date: 'MM/dd/yyyy' }}
                            </ng-container>

                            <ng-template #currencyFormat>
                                <ng-container *ngIf="isCurrency(field); else decimalFormat">
                                    {{ item[field] | currency:'USD':'symbol':'1.2-2' }}
                                </ng-container>

                                <ng-template #decimalFormat>
                                    <ng-container *ngIf="shouldFormatValue(field); else noFormatDisplay">
                                        {{ formatValues(item[field]) }}
                                    </ng-container>

                                    <ng-template #noFormatDisplay>
                                        {{ item[field] | decimalFormat }}
                                    </ng-template>
                                </ng-template>
                            </ng-template>
                            <p *ngIf="item[field] == null || item[field] == ''">
                                <span>_</span>
                            </p>
                            <span *ngIf="field == 'api_token'">
                                &nbsp;<i class="fa-solid fa-copy cursor-pointer" aria-hidden="true"
                                    (click)="onCopyClick(item)"></i>
                            </span>
                        </td>
                    </ng-container>
                    <td *ngIf="showAddColumn || showEditColumn || showDeleteColumn" style="text-align: center;">
                        <i *ngIf="showAddColumn" class="fa-solid fa-plus cursor-pointer" (click)="onAddClick(item)"
                            style="margin-right: 50px;"></i>
                        <i *ngIf="showEditColumn" class="fa-solid fa-pencil cursor-pointer" (click)="onEditClick(item)"
                            style="margin-right: 50px;"></i>
                        <i *ngIf="showDeleteColumn" class="fa-solid fa-trash-can cursor-pointer"
                            (click)="onDeleteClick(item)"></i>
                    </td>
                    <!-- <td *ngIf="showDeleteColumn" style="text-align: center;">
                        <i class="fa-solid fa-trash-can cursor-pointer" (click)="onDeleteClick(item)"></i>
                    </td> -->
                    <td *ngIf="showActionsColumn">
                        <select name="action" id="action" class="form-control form-control-lg action-select"
                            style="appearance: auto;" (change)="onActionChange($event.target, item)">

                            <option selected hidden value="-1">Action</option>
                            <option *ngFor="let action of actionDropdownConfig" [value]="action.value"
                                [hidden]="!action.condition(item)">
                                {{ action.label }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>

</ng-container>

<span *ngIf="gridData.length<1">
    {{noGridDataMessage}}
</span>