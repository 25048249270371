import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rc-admin-generic-grid-template',
  templateUrl: './admin-generic-grid-template.component.html',
  styleUrls: ['./admin-generic-grid-template.component.css']
})
export class AdminGenericGridTemplateComponent implements OnInit {
  @Input() gridData: Array<any> = [];
  @Input() gridHeaderMap: Array<any> = [];
  @Input() gridColumnList: Array<string> = [];
  @Input() currencyColumns: Array<string> = [];
  @Input() formatValueColumns: Array<string> = [];
  @Input() columnsNotToShowList: Array<string> = [];
  @Input() actionDropdownConfig: Array<any> = [];

  @Input() valueFormatMap: any = {};

  @Input() showAddColumn: boolean = false;
  @Input() showEditColumn: boolean = false;
  @Input() showDeleteColumn: boolean = false;
  @Input() showActionsColumn: boolean = false;
  @Input() showCopyIcon: boolean = false;

  @Input() noGridDataMessage: string = '';

  @Output() addClicked = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<any>();
  @Output() copyClicked = new EventEmitter<any>();
  @Output() actionChanged = new EventEmitter<any>();
  

  constructor() { }
  
  ngOnInit(): void { }

  showFields(field: string) {    
    return this.columnsNotToShowList.includes(field);
  }

  onAddClick(rowData: any) {
    this.addClicked.emit(rowData)
  }

  onEditClick(rowData: any) {
    this.editClicked.emit(rowData)
  }

  onDeleteClick(rowData: any) {
    this.deleteClicked.emit(rowData)
  }

  onCopyClick(rowData: any) {
    this.copyClicked.emit(rowData)
  }

  onActionChange(target: any, rowData: any) {
    this.actionChanged.emit({
      selectedAction: target,
      rowData: rowData
    })
  }

  isDate(value: any): boolean {
    const dateFormats = [
      /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
      /^\d{2}\/\d{2}\/\d{4}$/, // MM/DD/YYYY
      /^\d{4}\/\d{2}\/\d{2}$/, // YYYY/MM/DD
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/ // ISO 8601 datetime with optional milliseconds: YYYY-MM-DDTHH:mm:ss.sss...
    ];

    if (typeof value === 'string') {
      for (const format of dateFormats) {
        if (format.test(value)) {
          // Check if the date is valid
          const date = new Date(value);
          return !isNaN(date.getTime());
        }
      }
    }

    return false;
  }

  isCurrency(value: any): boolean {
    return this.currencyColumns.includes(value);
  }

  isNumber(value: any): boolean {
    return typeof value == 'number' ? true : false;
  }

  shouldFormatValue(value: any): boolean{
    return this.formatValueColumns.includes(value);
  }

  formatValues(value: string): string {    
    return this.valueFormatMap[value]
  }


}
