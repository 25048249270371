import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class StateStorageService {
  private renderer: Renderer2;
  private clickHandler!: () => void;
  private isBlocking = false;

  private previousUrlKey = 'previousUrl';

  constructor(
    private sessionStorageService: SessionStorageService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  storeUrl(url: string): void {
    this.sessionStorageService.store(this.previousUrlKey, url);
  }

  getUrl(): string | null {
    return this.sessionStorageService.retrieve(this.previousUrlKey) as string | null;
  }

  clearUrl(): void {
    this.sessionStorageService.clear(this.previousUrlKey);
  }

  enableBlocking(): void {
    if (this.isBlocking) return;

    this.clickHandler = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      event.stopImmediatePropagation();
      event.preventDefault();
    });
    this.isBlocking = true;
  }

  disableBlocking(): void {
    if (this.clickHandler) {
      this.clickHandler();
    }
    this.isBlocking = false;
  }
  
}
