import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from "@ng-select/ng-select";
import { MaterialModule } from '../material.module';

import { ConfirmationModalComponent } from './component/confirmation-modal/confirmation-modal.component';
import { HasAnyAuthorityDirective } from './directive/has-any-authority.directive';
import { HeaderComponent } from './component/header/header.component';
import { AddUserModalComponent } from './component/add-user-modal/add-user-modal.component';
import { AddEntityModalComponent } from './component/add-entity-modal/add-entity-modal.component';
import { SelectEntityModalComponent } from './component/select-entity-modal/select-entity-modal.component';
import { ChangePasswordModalComponent } from './component/change-password-modal/change-password-modal.component';
import { DetailsModalComponent } from './component/details-modal/details-modal.component';
import { GenericGridTemplateComponent } from './component/generic-grid-template/generic-grid-template.component';
import { ColumnFilterModalComponent } from './component/column-filter-modal/column-filter-modal.component';
import { DecimalFormatPipe } from './pipe/decimal-format.pipe';
import { SimpleGenericModalComponent } from './component/simple-generic-modal/simple-generic-modal.component';
import { DynamicQueryModalComponent } from './component/dynamic-query-modal/dynamic-query-modal.component';
import { NgxAngularQueryBuilderModule } from "ngx-angular-query-builder";
import { FileUploadMappingsComponent } from './component/file-upload-mappings/file-upload-mappings.component';
import { AdminGenericGridTemplateComponent } from './component/admin-generic-grid-template/admin-generic-grid-template.component';

@NgModule({
  declarations: [
    ConfirmationModalComponent,
    HasAnyAuthorityDirective,
    HeaderComponent,
    AddUserModalComponent,
    AddEntityModalComponent,
    SelectEntityModalComponent,
    ChangePasswordModalComponent,
    DetailsModalComponent,
    GenericGridTemplateComponent,
    ColumnFilterModalComponent,
    DecimalFormatPipe,
    SimpleGenericModalComponent,
    DynamicQueryModalComponent,
    FileUploadMappingsComponent,
    AdminGenericGridTemplateComponent,
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgxAngularQueryBuilderModule,
    NgSelectModule,
    MaterialModule
  ],
  exports: [
    HeaderComponent,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HasAnyAuthorityDirective,
    GenericGridTemplateComponent,
    DecimalFormatPipe,
    NgbPopoverModule,
    NgbPaginationModule,
    AdminGenericGridTemplateComponent
  ],
  providers: [
    CookieService
  ]
})
export class SharedModule { }
